import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import { Button, HeaderPlaceholder, Underline } from "../../styles/ui.styles";
import { SwiperWrapper } from "../../styles/swiper.styles";
import SubscribeForm from "../../components/subscribeForm";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { Navigation, Thumbs } from "swiper";
SwiperCore.use([Navigation, Thumbs]);
import { FaCheck } from "react-icons/fa";
import Configurator from "../../components/configurator/configurator";
import useScrollTo from "../../lib/scrollto";
import ExpandableItem from "../../components/expandableItem";

const YachtEdition: React.FC = () => {
  const { scrollTo } = useScrollTo();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  return (
    <>
      <SEO title={"Yacht Edition"} description={"Yacht Edition"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <SliderSection>
            <div>
              <h1>Yacht Edition</h1>
              <p>
                Fastrrr offers a refined and efficient training experience
                through gathering training data and providing insights based on
                the AI enhanced analytics solution, which will help professional
                sailors achieve better results and maximize their boats
                performance.
              </p>
              <Buttons>
                <Button
                  onClick={() => scrollTo("#configurator", -100)}
                  primary={true}
                >
                  CONFIGURE
                </Button>
              </Buttons>
            </div>
            <SwiperWrapper>
              <Swiper
                // loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                className="mainSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition1.jpg"}
                    alt={"Yacht Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition2.jpg"}
                    alt={"Yacht Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition3.jpg"}
                    alt={"Yacht Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                onSwiper={(swpr: SwiperCore) => setThumbsSwiper(swpr)}
                loop={false}
                spaceBetween={10}
                slidesPerView={3}
                freeMode={false}
                watchSlidesProgress={true}
                className="thumbnailSwiper"
              >
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition1.jpg"}
                    alt={"DinghyEdition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition2.jpg"}
                    alt={"Yacht Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src={"../../images/yacht_edition3.jpg"}
                    alt={"Yacht Edition"}
                    objectFit={"contain"}
                  />
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </SliderSection>

          <Title id="why" style={{ marginTop: "128px" }}>
            <h2>Why Yacht Edition?</h2>
            <Underline />
          </Title>

          <Whysection>
            <p>
              When racing keel boats, it is highly unusual for teams to join
              with a coach to train on a weekly basis. Mostly, there is only
              little time even for solo training sessions. To boost performance
              the use of sophisticated tools that provide instant feedback and
              insights on a sailor’s performance are becoming more and more
              essential. With the comprehensive Fastrrr system you can greatly
              enhance your performance even while sailing alone. It is done with
              continuous gathering of your boats data with the help of the Yacht
              Edition on-board hardware and the easily accessible DataBay
              analytics interface.
            </p>
          </Whysection>

          <Title>
            <h2>Topology</h2>
            <Underline />
          </Title>

          <div style={{ width: "100%" }}>
            <StaticImage
              style={{ width: "100%" }}
              src={"../../images/yacht_graph.png"}
              alt={"DinghyEdition Graph"}
              objectFit={"contain"}
            />
          </div>

          <Title>
            <h2>Features</h2>
            <Underline />
          </Title>

          <FeaturesGrid>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Increase speed
                  </StyledListElement>
                  <p>
                    Collect data and we deliver sailing insights to help you
                    maximize performance{" "}
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Compatibility
                  </StyledListElement>
                  <p>NMEA2000 and NMEA0183 compatible.</p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Real time analysis
                  </StyledListElement>
                  <p>
                    By using Fastrrr, you not only get the momentarily data, but
                    with real time analysis you get the optimal targets for the
                    current conditions and the settings needed for maximal
                    performance
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Easy data access
                  </StyledListElement>
                  <p>Available sailing data from any device.</p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Cloud system
                  </StyledListElement>
                  <p>
                    You can sync your photos or videos recorded during sailing
                    and check what happened on the boat during any event{" "}
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Remote coaching
                  </StyledListElement>
                  <p>
                    Helps you train more effectively through the DataBay
                    platform.{" "}
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Accuracy
                  </StyledListElement>
                  <p>
                    Fastrrr’s cutting edge industrial grade GPS and sensor
                    fusion technology gives outstanding accuracy.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Data security
                  </StyledListElement>
                  <p>No one has access to your own sailing data.</p>
                </li>
              </ul>
            </div>
          </FeaturesGrid>

          <Title>
            <h2>What’s more?</h2>
            <Underline />
          </Title>

          <WhatsMoreGrid>
            <div>
              <p>
                <strong>
                  The on-board unit allows sailing sessions to be more
                  effective, by having an instantly accessible view of the boat
                  motion data, weather conditions, and gives live analyzes.
                </strong>
                <br />
                <br />
                During sailing, you can also sync your photos or videos recorded
                during sailing and measured data, especially from the top of the
                mast down to the sails, this way sailors could check each sail
                and rig settings after trainings and competitions to analyze it
                with recorded performance data. a.
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Easy to install
                  </StyledListElement>
                  <p>
                    Quad Lock® is the strongest and most secure mounting system
                    in the world that holds your device steady in rough
                    conditions.
                  </p>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Boat settings log
                  </StyledListElement>
                  <p>
                    Never lose a sail or boat setting, store everything in the
                    cloud.
                  </p>
                </li>
              </ul>
            </div>
          </WhatsMoreGrid>

          <Title>
            <h2>More details</h2>
            <Underline />
          </Title>
          <ExpandableGrid>
            <ExpandableItem
              title={"What’s in the box?"}
              expandableText={
                "– Yacht Edition instrument\n– Power cable\n– N2K drop cable 0.6m\n– Mounting bracket\n\nPlease notice our product manual is only available online. Please download the latest version of manual from the support page."
              }
            />
            <ExpandableItem
              title={"Features"}
              expandableText={
                "– 10 Hz refresh rate GPS\n– 100Hz refresh rate IMU sensor\n– Data download over-the-air\n– Automatic magnetic declination\n– Waterproof (IPX8) to 3m\n– Two-year warranty"
              }
            />
            <ExpandableItem
              title={"Technical specifications"}
              expandableText={
                "– GPS networks: multi-GNSS (GPS/QZSS, GLONASS and BeiDou)\n– GPS Frequency: 10 Hz\n– Inertial Measurement Unit: 9 axis 1kHz\n– Compass: 3 axis magnetometer, compensated with 3 gyroscopes\n– Size: 140x80x75 mm\n– Weight: 450gr\n– Supply voltage: 12V DC"
              }
            />
            <ExpandableItem
              title={"Shipping details"}
              expandableText={
                "Your order will be processed and shipped from our office in the Hungary (Veszprem). Depending on where your order is shipped to, the following approximate delivery times will apply: \n\n – Europe: 3-5 business days* \n – Outside Europe: 5-7 business days* \n – Worldwide shipping will be handled by UPS (Track and Trace included) \n\n * No rights can be derived from these approximate delivery times"
              }
            />
          </ExpandableGrid>
        </Wrapper>
        <div id="configurator" />
        <Configurator forBoatType={"yacht"} />
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default YachtEdition;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;

  p {
    color: var(--color-dark-grey);
  }
`;

const SliderSection = styled.section`
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 64px;

  div:first-child {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 32px;
`;

const Title = styled.div`
  margin: 70px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    margin-top: 32px;
  }
  div {
    margin: 32px 0;
  }
`;

const Whysection = styled.section`
  columns: 400px 2;
  column-gap: 32px;
  p {
    margin-top: 0;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
    background: #f3f3f3;
    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledListElement = styled.strong`
  display: flex;
  align-items: center;
  svg {
    min-width: 16px;
  }
`;

const WhatsMoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div {
    padding: 32px;
  }

  div:nth-child(2) {
    background: #f3f3f3;

    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ExpandableGrid = styled.div`
  p {
    color: unset;
  }
  display: grid;
  white-space: pre-line;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const Graphs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
